import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import screenfullModule from 'screenfull';
import { Icon } from '@seeqdev/qomponents';
import { HeaderLogo } from '@/header/HeaderLogo.atom';
import { HamburgerMenu } from '@/header/HamburgerMenu.atom';
import { ViewModeWorksheetActions } from '@/header/ViewModeWorksheetActions.molecule';
import { WorksheetWatchers } from '@/worksheets/WorksheetWatchers.atom';
import { AccessKeyModal } from '@/header/AccessKeyModal.organism';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { setUserPreferencesDisplay, setUserPreferencesTab, setUserProfileDisplay } from '@/workbench/workbench.actions';
import { ItemAclModal } from '@/accessControl/ItemAclModal.molecule';
import { WorkstepIcons } from '@/header/WorkstepIcons.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { EditableText } from '@/core/EditableText.atom';
import { renameWorksheet, setWorkbookName } from '@/workbook/workbook.actions';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import { logout as logoutSqAuth } from '@/utilities/authentication.utilities';
import { doTrack } from '@/track/track.service';
import { canModifyWorkbook } from '@/services/authorization.service';
import { translateBreadcrumb } from '@/utilities/homescreen.utilities';
import { EditUserProfile } from '@/header/EditUserProfile.atom';
import { EditUserPreferencesModal } from '@/header/EditUserPreferencesModal.atom';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { getFolderLink, getWorkbooksLink, isWorksheet } from '@/main/routing.utilities';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRoutingData } from '@/main/useRoutingData.hook';
import { WORKBOOK_DISPLAY, WorkbookItemPreview } from '@/workbook/workbook.constants';
import { WrapperWithLink } from '@/core/WrapperWithLink.atom';
import { HeaderAIAssistant } from '@/aiAssistant/HeaderAIAssistant.atom';
import { genAIEnabled } from '@/services/systemConfiguration.utilities';
import { PREFERENCE_TABS } from '@/workbench/workbench.store';
import { isMobileDevice } from '@/utilities/utilities';
import { resetJournalToolbar, resetLayout } from '@/main/layout.actions';
import { LayoutControls } from './LayoutControls.atom';
// This module has really funky type of false | Screenfull - force it to a reasonable type
const screenfull = screenfullModule as screenfull.Screenfull;

const toggleFullScreen = () => {
  screenfull.toggle();
};

export const Header: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { isWorksheetDisplayed, workbookDisplayMode } = useRoutingData();
  const [modalShown, setModalShown] = useState<'accessControl' | 'accessKeys' | 'none'>('none');
  const ancestors = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.ancestors);
  const userHasManagePermission = useFluxPath(sqWorkbookStore, () => sqWorkbookStore?.effectivePermissions?.manage);
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);

  const isEditMode = isWorksheetDisplayed && workbookDisplayMode === WORKBOOK_DISPLAY.EDIT;
  const isViewMode = workbookDisplayMode === WORKBOOK_DISPLAY.VIEW;
  const isPresentationMode = isWorksheetDisplayed && workbookDisplayMode === WORKBOOK_DISPLAY.PRESENT;
  const isAdminTool = _.includes(pathname, '/administration');
  const { name, owner, effectivePermissions, isReportBinder } = useFlux(sqWorkbookStore);
  const storeDisplayUserProfile = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.displayUserProfile);
  const storeDisplayUserPreferences = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.displayUserPreferences);
  const userTimeZone = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userTimeZone);

  const currentUserMissingInfo =
    !sqWorkbenchStore.currentUser.firstName ||
    !sqWorkbenchStore.currentUser.lastName ||
    !sqWorkbenchStore.currentUser.email;

  const displayUserProfile =
    storeDisplayUserProfile || (currentUserMissingInfo && !isPresentationMode && !headlessRenderMode());

  const displayUserPreferences =
    storeDisplayUserPreferences || (!userTimeZone && !isPresentationMode && !headlessRenderMode());

  const workbook = {
    name,
    owner,
    effectivePermissions,
  };
  const breadcrumbs = isEditMode && canModifyWorkbook(workbook) ? ancestors : [];
  const renderDropdownEntry = (
    { href = '#', iconClass = '', translationKey = '', action = _.noop, display = false, divider = false, testId = '' },
    index: number,
  ) => {
    if (display) {
      return (
        <Dropdown.Item
          href={href}
          key={`dropdown_${translationKey}`}
          onClick={action}
          target="_blank"
          className="sq-force-text-gray"
          data-testid={testId}>
          <Icon icon={`${iconClass} fa-fw`} extraClassNames="pr10" type="inherit" />
          <span className="forceFont" data-testid={`moreActions_${translationKey}`}>
            {t(translationKey)}
          </span>
        </Dropdown.Item>
      );
    }
    if (divider) {
      return <div className="dropdown-divider" key={index} />;
    }
  };
  const doResetLayout = () => {
    resetLayout(true);
    resetJournalToolbar(true);
  };

  const actions = [
    {
      href: '#',
      iconClass: 'fa-cog',
      translationKey: 'USER.EDIT_PREFERENCES',
      action: () => {
        if ((isWorksheet() || isViewMode) && !isReportBinder) {
          setUserPreferencesTab(PREFERENCE_TABS.WORKSHEET);
        } else {
          setUserPreferencesTab(PREFERENCE_TABS.SYSTEM);
        }
        setUserPreferencesDisplay(true);
      },
      display: true,
      testId: 'editPreferences',
    },
    {
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fc-bell-cog',
      translationKey: 'NOTIFICATIONS_MANAGEMENT.TITLE',
      action: () => window.open('/notifications-management', 'SeeqNotificationsManagement'),
      display: true,
      testId: 'notifications',
    },
    {
      href: '#',
      iconClass: 'fa-envelope',
      translationKey: 'NOTIFICATIONS_HISTORY.TITLE',
      action: () => window.open('/notifications-history', 'SeeqNotificationsHistory'),
      display: true,
      testId: 'notifications-history',
    },
    {
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fa-key',
      translationKey: 'ACCESS_KEY.ACCESS_KEYS',
      action: () => setModalShown('accessKeys'),
      display: true,
      testId: 'accessKeys',
    },
    {
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fa-right-from-bracket',
      translationKey: 'LOGOUT',
      action: logout,
      display: true,
      testId: 'logout',
    },
  ];

  function logout() {
    logoutSqAuth(_.join(_.compact([pathname === '/' ? '' : pathname, searchParams.toString()]), '?'));
  }

  const getLink = (crumb: WorkbookItemPreview) =>
    crumb.id && !crumb.tabHash ? getFolderLink(crumb.id) : getWorkbooksLink(crumb.tabHash);

  return (
    <header className="mainHeader flexColumnContainer flexCenter" data-testid="header" id="header">
      <HeaderLogo />

      <div className="flexGrowShrink flexRowContainer flexCenter flexNoOverflow">
        <div className="flexNoGrow flexNoOverflow flexColumnContainer flexCenter">
          {!!breadcrumbs.length && isWorksheetDisplayed && (
            <h5 className="mt0 mb2 fs11">
              <ul className="unstyled-list text-not-bold flexColumnContainer headerBreadcrumbs folderBreadcrumbs mt0 mb0 flexNoGrow">
                {_.map(breadcrumbs, (crumb) => {
                  return (
                    <li className="ml2 " tabIndex={0} key={`crumbId_${crumb.id}`}>
                      {' '}
                      <WrapperWithLink link={getLink(crumb)}>
                        <span
                          className="text-white"
                          onClick={() => navigate(getLink(crumb))}
                          data-testid={translateBreadcrumb(crumb)}>
                          {translateBreadcrumb(crumb)}
                        </span>
                      </WrapperWithLink>
                    </li>
                  );
                })}
              </ul>
            </h5>
          )}
        </div>

        <div
          id="workbookFullName"
          className="flexNoGrow flexNoOverflow flexColumnContainer flexNoGrowNoShrink flexCenter truncatedLabel max-height-23">
          {sqWorkbenchStore.stateParams.worksheetId && (
            <EditableText
              allowEditing={canModifyWorkbook(workbook) && isEditMode}
              onUpdate={(name) => setWorkbookName(sqWorkbenchStore.stateParams.workbookId, name, sqWorkbookStore.name)}
              value={workbook.name}
              inputClasses="workbookName truncatedLabel headerLabel max-height-23 mt0 mb0"
              textClasses={breadcrumbs.length ? 'fs14' : 'fs17'}
              testId="workbookName"
              id="workbookName"
            />
          )}
          {workbook.name && sqWorkbenchStore.stateParams.worksheetId && (
            <p className="truncatedLabel pl3 pr3 pb2 fs17 mt0 mb0">-</p>
          )}
          {sqWorkbenchStore.stateParams.worksheetId && (
            <EditableText
              allowEditing={canModifyWorkbook(workbook) && isEditMode}
              onUpdate={(name) => renameWorksheet(sqWorkbenchStore.stateParams.worksheetId, name)}
              value={
                sqWorkbenchStore.stateParams.worksheetId &&
                sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)
              }
              textClasses={breadcrumbs.length ? 'fs14' : 'fs17'}
              testId="worksheetName"
              id="worksheetName"
              autoWidth={true}
            />
          )}
        </div>
      </div>

      <WorkstepIcons isEditMode={isEditMode} isViewMode={isViewMode} />
      <div className="flexColumnContainer">
        <ViewModeWorksheetActions isEditMode={isEditMode} isViewMode={isViewMode} />
      </div>
      {isEditMode && isViewMode && (
        <WorksheetWatchers compactMode={false} worksheetId={sqWorkbenchStore.stateParams.worksheetId} />
      )}
      {canModifyWorkbook(workbook) && isEditMode && (
        <HoverTooltip placement="bottom" text="ACL_STATUS.TOOLTIP.MANAGE_ACCESS_CONTROL">
          <div
            data-testid="manageAccessControl"
            className="sq-navbar-default fs13 flexColumnContainer flexAlignCenter flexJustifyEnd ml5 mr9 flexNoGrowNoShrink"
            onClick={() => {
              doTrack('Access Control', 'open', 'from toolbar');
              setModalShown('accessControl');
            }}>
            <div className="cursorPointer">
              <Icon
                icon="fa-kit fa-sharp-regular-user-group-circle-plus"
                extraClassNames="fa-xlg mr7 cursorPointer sq-navbar-default link-no-underline"
                type="inherit"
              />
              <span>{t('ACCESS_CONTROL.SHARE')}</span>
            </div>
          </div>
        </HoverTooltip>
      )}
      {modalShown === 'accessControl' && (
        <ItemAclModal
          itemId={stateParams.workbookId}
          workbookId={stateParams.workbookId}
          worksheetId={stateParams.worksheetId}
          folderId={stateParams.currentFolderId}
          includeLinksAndCorporateMessage={true}
          closeModal={() => setModalShown('none')}
          isAclModalLocationInHeader={true}
          userHasManagePermission={userHasManagePermission}
        />
      )}
      {!isPresentationMode && isWorksheet() && <LayoutControls />}
      {genAIEnabled() && !isPresentationMode && !isAdminTool && !(isMobileDevice() && isViewMode) && (
        <HeaderAIAssistant />
      )}
      {!isPresentationMode && (
        <div
          className="ml5 headerOptionsButton flexColumnContainer cursorPointer sq-navbar-default flexNoGrowNoShrink flexAlignCenter"
          id="userMenu">
          <ButtonWithPopover
            label={
              <>
                <Icon
                  icon="fa-user"
                  extraClassNames="fa-xlg flexNoGrowNoShrink mr5"
                  type="inherit"
                  testId="userProfileBtn"
                  id="userProfile"
                />
                <span>{sqWorkbenchStore.currentUser.firstName}</span>
              </>
            }
            popoverConfig={{ id: 'userProfile', placement: 'bottom' }}>
            {_.map(actions, (action, index) => renderDropdownEntry(action, index))}
          </ButtonWithPopover>
        </div>
      )}
      <div className="flexColumnContainer mt15 mb10 flexNoGrowNoShrink">
        <HamburgerMenu toggleFullScreen={toggleFullScreen} initialFullScreenState={screenfull.isFullscreen} />
      </div>

      {modalShown === 'accessKeys' && <AccessKeyModal show={true} onClose={() => setModalShown('none')} />}

      {displayUserProfile && (
        <EditUserProfile
          closeFn={() => {
            setUserProfileDisplay(false);
          }}
        />
      )}

      {displayUserPreferences && (
        <EditUserPreferencesModal
          closeFn={() => {
            setUserPreferencesDisplay(false);
            setUserPreferencesTab(PREFERENCE_TABS.SYSTEM);
          }}
        />
      )}
    </header>
  );
};
