import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { ChartToolPanelsDef, ColDef, GridApi } from '@ag-grid-community/core';
import _ from 'lodash';
import { setChartViewConditionSettings } from '@/tableBuilder/tableBuilder.actions';

export const persistChart = (isInteractiveContent: boolean, api: GridApi<any> | undefined) => {
  if (!isInteractiveContent && !headlessRenderMode()) {
    setChartViewConditionSettings(_.first(api?.getChartModels()));
  }
};

export const regenerateChart = (containerId: string, api: GridApi<any> | undefined, columnDefs: ColDef<any, any>[]) => {
  api?.createRangeChart({
    chartContainer: document.querySelector(containerId) as HTMLElement,
    cellRange: {
      columns: columnDefs.map((column) => column.colId) as string[],
    },
    chartType: 'stackedColumn',
  });
};

export const chartToolPanelsDef: ChartToolPanelsDef = {
  settingsPanel: {
    chartGroupsDef: {
      columnGroup: ['column', 'stackedColumn', 'normalizedColumn'],
      barGroup: ['bar', 'stackedBar', 'normalizedBar'],
      pieGroup: ['donut', 'pie'],
      scatterGroup: ['scatter', 'bubble'],
      hierarchicalGroup: ['treemap', 'sunburst'],
      lineGroup: ['line'],
      specializedGroup: ['waterfall', 'heatmap'],
      areaGroup: ['area', 'stackedArea', 'normalizedArea'],
      polarGroup: ['radarLine', 'radarArea', 'nightingale', 'radialColumn', 'radialBar'],
      statisticalGroup: ['histogram', 'boxPlot', 'rangeBar', 'rangeArea'],
    },
  },
  dataPanel: {
    groups: [
      { type: 'seriesChartType', isOpen: true },
      { type: 'categories', isOpen: true },
      { type: 'series', isOpen: true },
    ],
  },
  formatPanel: {
    groups: [
      { type: 'chart', isOpen: false },
      { type: 'legend', isOpen: false },
      { type: 'horizontalAxis', isOpen: false },
      { type: 'verticalAxis', isOpen: false },
      { type: 'series', isOpen: false },
    ],
  },
};
