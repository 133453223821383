import { PersistenceLevel, Store } from '@/core/flux.service';
import { AnyProperty } from '@/utilities.types';
import _ from 'lodash';

export class LayoutStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqLayoutStore';

  initialize() {
    this.state = this.immutable({
      assetGroupEditorVisible: false,
      tableDefinitionEditorVisible: false,
      displayPaneAddOnCapsulesPanelEnabled: false,
      displayPaneAddOnDetailsPanelEnabled: false,
      trendVisible: true,
      autofocusToolsTab: false,
      reset: false,
      resetJournalToolbar: false,
      journalTabVisible: false,
      sidePanelCollapsed: false,
      layoutLocked: false,
      calculationHierarchyInTab: false,
    });
  }
  get journalTabVisible(): boolean {
    return this.state.get('journalTabVisible');
  }

  get autofocusToolsTab(): boolean {
    return this.state.get('autofocusToolsTab');
  }

  get reset(): boolean {
    return this.state.get('reset');
  }

  get resetJournalToolbar(): boolean {
    return this.state.get('resetJournalToolbar');
  }

  get assetGroupEditorVisible(): boolean {
    return this.state.get('assetGroupEditorVisible');
  }

  get tableDefinitionEditorVisible(): boolean {
    return this.state.get('tableDefinitionEditorVisible');
  }

  get displayPaneAddOnCapsulesPanelEnabled(): boolean {
    return this.state.get('displayPaneAddOnCapsulesPanelEnabled');
  }

  get displayPaneAddOnDetailsPanelEnabled(): boolean {
    return this.state.get('displayPaneAddOnDetailsPanelEnabled');
  }

  get trendVisible(): boolean {
    return this.state.get('trendVisible');
  }

  get calculationHierarchyInTab(): boolean {
    return this.state.get('calculationHierarchyInTab');
  }

  get sidePanelCollapsed(): boolean {
    return this.state.get('sidePanelCollapsed');
  }

  get layoutLocked(): boolean {
    return this.state.get('layoutLocked');
  }
  dehydrate() {
    return _.omit(this.state.serialize(), ['reset', 'resetJournalToolbar', 'journalTabVisible']);
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    LAYOUT_SET_ASSET_GROUP_EDITOR_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('assetGroupEditorVisible', visible);
    },

    LAYOUT_SET_TABLE_DEFINITION_EDITOR_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('tableDefinitionEditorVisible', visible);
    },

    LAYOUT_SET_DISPLAY_PANE_ADD_ON_CAPSULES_PANEL_ENABLED: ({ enabled }: { enabled: boolean }) => {
      this.state.set('displayPaneAddOnCapsulesPanelEnabled', enabled);
    },

    LAYOUT_SET_DISPLAY_PANE_ADD_ON_DETAILS_PANEL_ENABLED: ({ enabled }: { enabled: boolean }) => {
      this.state.set('displayPaneAddOnDetailsPanelEnabled', enabled);
    },
    LAYOUT_SET_TREND_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('trendVisible', visible);
    },

    LAYOUT_SET_AUTO_FOCUS_TOOLS_TAB: ({ autofocus }: { autofocus: boolean }) => {
      this.state.set('autofocusToolsTab', autofocus);
    },

    LAYOUT_RESET: ({ reset }: { reset: boolean }) => {
      this.state.set('reset', reset);
    },
    LAYOUT_RESET_JOURNAL_TOOLBAR: ({ reset }: { reset: boolean }) => {
      this.state.set('resetJournalToolbar', reset);
    },
    LAYOUT_SET_JOURNAL_TAB_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('journalTabVisible', visible);
    },
    LAYOUT_SET_SIDE_PANEL_COLLAPSED: ({ collapsed }: { collapsed: boolean }) => {
      this.state.set('sidePanelCollapsed', collapsed);
    },
    LAYOUT_SET_LAYOUT_LOCKED: ({ locked }: { locked: boolean }) => {
      this.state.set('layoutLocked', locked);
    },
    LAYOUT_SET_CALCULATION_HIERARCHY_IN_TAB: ({ inTab }: { inTab: boolean }) => {
      this.state.set('calculationHierarchyInTab', inTab);
    },
  };
}
