import {
  ColumnDefinitionInputV1,
  ColumnRuleAncestorInputV1,
  ColumnRuleAssetCreatorInputV1,
  ColumnRuleConcatInputV1,
  ColumnRuleConstantInputV1,
  ColumnRuleDescendantInputV1,
  ColumnRuleEventPropertyInputV1,
  ColumnRuleFormulaCreatorInputV1,
  ColumnRuleInputV1,
  ColumnRuleItemPropertyInputV1,
  ColumnRulePathInputV1,
  ColumnRulePathSearchInputV1,
  ColumnRuleSetItemPropertyInputV1,
  ColumnRuleTreePathCreatorInputV1,
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { PropertyMatchOperatorEnum } from '@/sdk/model/ColumnRuleDescendantInputV1';
import { ScalingTableColumnDefinition } from '@/tableDefinitionEditor/tableDefinition.types';
import { ParameterWithMetadata } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';

export type ColumnRuleInputParameters =
  | ColumnRuleItemPropertyInputV1
  | ColumnRulePathInputV1
  | ColumnRuleAncestorInputV1
  | ColumnRuleConcatInputV1
  | ColumnRuleDescendantInputV1
  | ColumnRuleEventPropertyInputV1
  | ColumnRuleFormulaCreatorInputV1
  | ColumnRuleAssetCreatorInputV1
  | ColumnRuleTreePathCreatorInputV1
  | ColumnRuleConstantInputV1
  | ColumnRuleSetItemPropertyInputV1
  | ColumnRulePathSearchInputV1;

export type CombinedColumnRuleInputParameters = ColumnRuleItemPropertyInputV1 &
  ColumnRulePathInputV1 &
  ColumnRuleAncestorInputV1 &
  ColumnRuleConcatInputV1 &
  ColumnRuleDescendantInputV1 &
  ColumnRuleEventPropertyInputV1 &
  ColumnRuleFormulaCreatorInputV1 &
  ColumnRuleAssetCreatorInputV1 &
  ColumnRuleTreePathCreatorInputV1 &
  ColumnRuleConstantInputV1 &
  ColumnRuleSetItemPropertyInputV1 &
  ColumnRulePathSearchInputV1;

export type ColumnRule = keyof ColumnRuleInputV1;
export type RuleParameter = keyof CombinedColumnRuleInputParameters;

// TODO CRAB-41437 unrestrict these permissions options
export const restrictedParams: string[] = [
  SeeqNames.MaterializedTables.Parameters.IdentityId,
  SeeqNames.MaterializedTables.Parameters.PermissionString,
  SeeqNames.MaterializedTables.Parameters.SecurityString,
];

export const PropertyMatchOperatorEnumToLabel: Record<PropertyMatchOperatorEnum, string> = {
  [PropertyMatchOperatorEnum.EQUALS]: 'Equals',
  [PropertyMatchOperatorEnum.EQUALSIGNORECASE]: 'Equals Ignore Case',
  [PropertyMatchOperatorEnum.NOTEQUALS]: 'Not Equals',
  [PropertyMatchOperatorEnum.WITHIN]: 'Within',
  [PropertyMatchOperatorEnum.STRINGCONTAINS]: 'String Contains',
};

export const ColumnTypeOptions = [
  {
    value: ColumnTypeEnum.TEXT,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.TEXT.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.TEXT.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.NUMERIC,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.NUMERIC.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.NUMERIC.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.UUID,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.UUID.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.UUID.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.TIMESTAMPTZ,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.TIMESTAMPTZ.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.TIMESTAMPTZ.DESCRIPTION',
  },
  {
    value: ColumnTypeEnum.BOOLEAN,
    label: 'SCALING.COLUMN_TYPE_OPTIONS.BOOLEAN.LABEL',
    description: 'SCALING.COLUMN_TYPE_OPTIONS.BOOLEAN.DESCRIPTION',
  },
] as const;

export const AllColumnEnumOptions = Object.values(ColumnTypeEnum) as ColumnTypeEnum[];

export interface BaseInputParametersProps {
  setParameter: (parameter: RuleParameter, value: any) => void;
  testId: string;
  ruleParameterWithMetadata: ParameterWithMetadata;
  currentValue?: any;
}

export interface TextInputParameterProps extends BaseInputParametersProps {
  placeholderOverride?: string;
}

export interface BooleanInputProps extends BaseInputParametersProps {
  disabled?: boolean;
}

export interface ColumnDropdownInputProps extends BaseInputParametersProps {
  removeParameter: (parameter: RuleParameter) => void;
  allColumnDefinitions: ScalingTableColumnDefinition[];
  currentColumnDefinition?: ColumnDefinitionInputV1;
}

export interface CombinedInputProps extends TextInputParameterProps, ColumnDropdownInputProps, BooleanInputProps {}

export interface ColumnRuleInputWithUIMetadata extends ColumnRuleInputV1 {
  isCollapsed?: boolean;
}
